import React from "react";
import './Header.css';
import {AppContext, AppContextType} from "../../AppContext";
import {goTo} from "../../Utils";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";

class Props {
    title: string = '';
    subtitle?: string = '';
    linkButton?: any;
    labelButton?: any;
    gallery?: any[] = [];
}

class State {

}


export class Header extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);

        this.state = {}
    }

    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
    }

    render() {

        return <div className={'header-container padded padded-vertical'}>
            <div className={'title-header-div padded '}>
                <div className={'font-36-600'}
                     dangerouslySetInnerHTML={{__html: this.props?.title}}>
                </div>
                {this.props?.subtitle?
                    <div className={'font-22-600'}
                         dangerouslySetInnerHTML={{__html: this.props?.subtitle}}>
                    </div>:null
                }

                <a href={this.props?.linkButton}
                   target={this.props?.linkButton?.startsWith('https') ? '_blank' : '_self'}>
                <button className={'btn '}
                       >
                    <p className={'font-14-500'}>
                        {this.props?.labelButton}
                    </p>
                </button>
                </a>
            </div>


            {/*Image*/}
            {this.props?.gallery && Object.keys(this.props?.gallery).length > 0 ?
                <div id={"imgCover"} className={'img-container-div  radius-25'} data-aos="fade-in"
                     data-aos-once="true">
                    <ImagesSlider autoplay={true}
                                  images={this.props?.gallery}></ImagesSlider>
                </div>
                : null}
        </div>;

    }
}

Header.contextType = AppContext;

