
import firebase from "./config/firebase";

//const db = firebase.ref("/drafts/listboxsit");
const db = firebase.ref("/projects/listboxsit");

class FirebaseService {
    getAll() {
        return db;
    }
}

export default new FirebaseService();
