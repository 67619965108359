import React, {Component, RefObject} from "react";
import "./Home.css";
import {AppContext, AppContextType} from "./AppContext";
import firebase from "firebase/app";
import {BulletList} from "./components/bullet-list/BulletList";
import {Header} from "./components/header/Header";
import {NewsGrid} from "./components/news-grid/NewsGrid";
import {Popup} from "./components/popup/Popup";
import {Helmet} from "react-helmet";

type Props = {
    data?: any;
};

type State = {
    home: any;
    imgToDisplay: string;
    imgToDisplayCustomer: string;
    popup: any;
};

class Home extends Component<Props, State> {
    subToolbar: RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.subToolbar = React.createRef();
    }

    componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/home');
        dbRef.on('value', snapshot => {
            this.setState({home: snapshot.val()})
        });

        const dbRefPopup = firebase.database().ref(ctx.basePath + '/pagine/popup');
        dbRefPopup.on('value', snapshot => {
            const popup = snapshot.val()

            if (popup?._visibility) {
                setTimeout(() => {
                    this.setState({
                        popup
                    })
                }, 3000)
            }
        });
    }




    render() {

        return (
            <div className={'home-container-div'}>
                {/* SEO */}
                <Helmet>
                    {this.state?.home?.seo?.meta_titolo?._value ?
                        <title>{this.state?.home?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                        <title>{process.env.REACT_APP_TITLE}</title>}
                    <meta name='description' content={this.state?.home?.seo?.meta_descrizione?._value as string} />
                    <meta name='keywords' content={this.state?.home?.seo?.meta_keywords?._value} />
                </Helmet>



                {/* Popup */}
                {
                    this.state?.popup ?
                        <Popup title={this.state?.popup?.titolo?._value}
                               description={this.state?.popup?.descrizione?._value}
                               labelButton={this.state?.popup?.pulsante?.etichetta?._value}
                               linkButton={this.state?.popup?.pulsante?.link?._value}
                               gallery={this.state?.popup?.gallery?._list}
                                popupID={this.state?.popup?.id_popup?._value}></Popup>
                        : null
                }


                {/* Home - Header */}
                <Header title={this.state?.home?.copertina?.titolo_copertina?._value}
                        subtitle={this.state?.home?.copertina?.sottotitolo_copertina?._value}
                        gallery={this.state?.home?.copertina?.immagini_copertina?._list}
                        labelButton={this.state?.home?.copertina?.pulsante_copertina?.etichetta_pulsante_copertina?._value}
                        linkButton={this.state?.home?.copertina?.pulsante_copertina?.link_pulsante_copertina?._value}></Header>


                {/* Home - Liste elenco puntato */}

                <div id={'lists-container-home'}>
                    {this.state?.home?.liste_elenco_puntato && this.state?.home?.liste_elenco_puntato?._list &&
                    Object.keys(this.state?.home?.liste_elenco_puntato._list).length > 0 ?
                        Object.keys(this.state?.home?.liste_elenco_puntato._list)
                            ?.filter(key => this.state?.home?.liste_elenco_puntato._list[key]?._id != 0)
                            ?.map(key => this.state?.home?.liste_elenco_puntato._list[key])
                            ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                            .map((item: any, index: number) => {
                                return <BulletList title={item?.titolo?._value}
                                                   description={item?.descrizione?._value}
                                                   headerButton={
                                                       {
                                                           etichetta: item?.pulsante?.etichetta?._value,
                                                           link: item?.pulsante?.link?._value
                                                       }
                                                   }
                                                   bulletList={item?.elenco_puntato?._list}
                                                   gallery={item?.gallery?._list}
                                                   key={'bl1' + index}></BulletList>
                            })
                        : null
                    }
                </div>


                {/* Home - News */}

                <NewsGrid title={this.state?.home?.news?.titolo_news?._value}
                             headerButton={{
                                 etichetta: this.state?.home?.news?.pulsante?.etichetta?._value,
                                 link: this.state?.home?.news?.pulsante?.link?._value
                             }}
                             gallery={this.state?.home?.news?.carosello_news?._list}/>




            </div>
        );
    }

    displayImg(index: string) {
        this.setState({imgToDisplay: index});
    }

    displayImgCustomer(index: string) {
        this.setState({imgToDisplayCustomer: index});
    }

}

Home.contextType = AppContext;
export default Home;
