import React from "react";
import './BulletList.css';
import {goTo} from "../../Utils";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";
import {AppContext, AppContextType} from "../../AppContext";

class Props {
    title: string = '';
    description: string = '';
    headerButton?: any;

    bulletList: any[] = [];

    gallery: any[] = [];
}

class State {
    imgToDisplay: number | string = 0;
}


export class BulletList extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);

        this.state = {
            imgToDisplay: 0
        }
    }

    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
    }

    displayImg(index: string) {
        this.setState({imgToDisplay: index});
    }

    render() {

        return <div
            className={'bullet-container padded-vertical ' + (this.props.bulletList && this.props.bulletList.length > 1 ? 'padding-bottom_0' : '')}>
            <div className={'padded'}>

                <div className={'bullet-list-header  flex flex-direction_row width_100'}>
                    <div id={"title"} className={'flex_1 font-36-600 text-align_left'}>
                        <p dangerouslySetInnerHTML={{__html: this.props?.title}}></p>
                    </div>

                    {
                        this.props?.headerButton && this.props?.headerButton?.etichetta ?
                            <div className={'flex_1 flex justify-content_end'}>

                                <button className={'btn accent-btn'}
                                        onClick={(event) => {
                                            goTo(this.props?.headerButton?.link || '', event)
                                        }}>
                                    <p className={'font-14-500'}>
                                        {this.props?.headerButton?.etichetta}
                                    </p>
                                </button>

                            </div>: null
                    }
                </div>
            </div>

            <div className={'bullet-subtitle font-22 padded'}
                 dangerouslySetInnerHTML={{__html: this.props?.description}}>

            </div>

            {(this.props.bulletList && Object.keys(this.props?.bulletList).filter(key => this.props?.bulletList[key as any]?._id != 0).length > 0)?
                <div className={"flex-container_1 list-bullet-container padded flex-direction_row-column "}>
                    <div className={"img-bullet-container list-title"}>
                        {
                            this.state?.imgToDisplay != null && this.props.bulletList && Object.keys(this.props.bulletList)
                                .filter(key => this.props?.bulletList[key as any]?._id != 0).length > 0 ?
                                (<div style={{
                                    backgroundImage: `url(${Object.keys(this.props.bulletList)
                                        .filter(key => this.props?.bulletList[key as any]?._id != 0)
                                        .map(key => this.props?.bulletList[key as any])[this.state.imgToDisplay as any]?.immagine_elemento?._value})`
                                }}
                                      className="background-bullet" data-aos="fade-in"
                                      data-aos-once="true"></div>) : null
                        }
                    </div>

                    {
                        this.props?.bulletList && Object.keys(this.props?.bulletList).filter(key => this.props?.bulletList[key as any]?._id != 0).length > 0 ?
                            <div className="list-bullet-content">
                                {Object.keys(this.props.bulletList || {})
                                    .filter((key: string) => this.props?.bulletList[key as any]?._id != 0)
                                    ?.map((key: string) => this.props?.bulletList[key as any])
                                    ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                                    .map((item: any, index: number) => {
                                        return (<div key={index} id={"azi" + index} className="list-data"
                                                     onMouseEnter={() => this.displayImg(index + "")}
                                                     onMouseLeave={() => this.displayImg(index + "")}
                                                     data-aos="fade-in"
                                                     data-aos-once="true"
                                                     data-aos-delay={100 * index}>

                                            <div className={'list-data-content'}>
                                                <div
                                                    className={'flex flex-direction_row gap_3vmin bullet-row align-items_center'}>
                                                    <i className={'icon-piu font-32-500'}></i>
                                                    <p className={"font-22-600"}
                                                       dangerouslySetInnerHTML={{__html: item?.titolo_elemento?._value}}></p>
                                                </div>

                                                <div className={'flex flex-direction_row gap_3vmin bullet-row'}>
                                                    <div></div>
                                                    <div className={'bullet-description-container'}>
                                                        <p className={"font-16 bullet-item-desc"}
                                                           dangerouslySetInnerHTML={{__html: item?.descrizione_elemento?._value}}></p>
                                                        {
                                                            item?.pulsante?.etichetta?._value ?
                                                                <a href={item?.pulsante?.link?._value}
                                                                   target={item?.pulsante?.link?._value?.startsWith('https') ? '_blank' : '_self'}>
                                                                    <button
                                                                        className={'btn bullet-btn dark-btn text-align_center font-12'}>

                                                                        {item?.pulsante?.etichetta?._value}

                                                                    </button>
                                                                </a>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                    })}
                            </div>
                            : null
                    }
                </div>
                : <></>
            }


            {/* Gallery */}
            {
                this.props?.gallery && Object.keys(this.props.gallery).filter(key => this.props.gallery[key as any]?._id != 0).length > 0 ?
                    <div
                        className={'img-container-div ' + (this.props?.bulletList && this.props?.bulletList.length > 1 ? '' : 'padded radius-25')}>
                        <ImagesSlider autoplay={true}
                                      images={Object.keys(this.props.gallery).filter(key => this.props.gallery[key as any]?._id != 0).map(key => this.props.gallery[key as any])}
                        ></ImagesSlider>
                    </div>
                    : null
            }

        </div>
    }
}

BulletList.contextType = AppContext;

